<template>
  <b-modal
    id="video-popup"
    modal-class="modal-add video-popup"
    :static="true"
    aria-labelledby="video-popup___BV_modal_title_"
  >
    <div slot="modal-header" class="header-video">
      <h5 class="modal-add-title" id="video-popup___BV_modal_title_">
        Видео презентация
      </h5>
      <div type="button" class="circle-close" @click="cancel"></div>
    </div>
    <div class="video-container">
      <video
        ref="video"
        class="video-block"
        controls
        poster="~@/assets/images/poster.png"
        src="~@/assets/EasyBase.mp4"
      ></video>
    </div>
    <div slot="modal-footer"></div>
  </b-modal>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'VideoPopup',
  methods: {
    ...mapMutations(['VIDEO']),
    cancel() {
      this.VIDEO(false);
      this.$bvModal.hide('video-popup');
      this.$refs.video.pause();
    }
  }
};
</script>

<style>
.video-popup.modal-add .modal-dialog {
  max-width: 100%;
  margin: 0;
}
.video-popup.modal-add {
  padding-left: 0;
}
.video-block {
  max-width: 100%;
}
.circle-close {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.circle-close::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 2px;
  border-radius: 50%;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.circle-close::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 2px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #ffffff;
}
.header-video {
  display: flex;
}
.modal-header {
  position: relative;
}
.video-container {
  display: flex;
  justify-content: center;
}
</style>
